<template>
  <section
    id="establishment_contact_infos"
    class="grid-zone"
  >
    <div
      v-if="!isGE && organisme.CONTACT && organisme.CONTACT.chef"
      id="contact"
    >
      <h2 class="s5 title-separator">
        <span class="bold">{{ $t('general.contact') }}</span>
      </h2>
      <div class="grid-zone">
        <div id="block_left">
          <IconWoman v-if="organisme.CONTACT.chef.genre === 'F'" />
          <IconMan v-else-if="organisme.CONTACT.chef.genre === 'H'" />
          <address class="text-regular">
            <span
              id="identite_contact"
              class="bold"
            >
              {{ organisme.CONTACT.chef.prenom }} {{ organisme.CONTACT.chef.nom }}
            </span>
            <span
              id="fonction_contact"
              class="bold"
            >
              {{ $t('general.chef-detablissement') }}
            </span>
            <span
              v-if="organisme.CONTACT.chef.tel"
              class="flex-vcenter"
            >
              <UilPhone size="16" />
              {{ organisme.CONTACT.chef.tel }}
            </span>
            <span
              v-if="organisme.CONTACT.chef.email"
              class="flex-vcenter"
            >
              <UilEnvelopeAlt size="16" />
              <a :href="`mailto:${organisme.CONTACT.chef.email}`">{{ organisme.CONTACT.chef.email }}</a>
            </span>
          </address>
        </div>
        <!-- TODO: Remettre quand on aura synchro les infos de l'établissement + changer grid-column: span 12 en 6
          <address id="block_right">
          <span class="text-medium">{{ $t('general.email') }}</span>
          <span class="text-regular">{{ organisme.CONTACT.autre.email }}</span>
          <span class="text-medium">{{ $t('general.site') }}</span>
          <span class="text-regular">{{ organisme.CONTACT.autre.site }}</span>
          <span class="text-medium">{{ $t('general.telephone') }}</span>
          <span class="text-regular">{{ organisme.CONTACT.autre.tel }}</span>
          <span class="text-medium">{{ $t('general.fax') }}</span>
          <span class="text-regular">{{ organisme.CONTACT.autre.fax }}</span>
          </address> -->
      </div>
    </div>

    <div id="identifiant">
      <h2 class="s5 title-separator">
        <span class="bold">{{ $t('general.donnees-identification') }}</span>
      </h2>
      <div>
        <div>
          <span class="text-medium">{{ $t('etablissement.compte-client') }}</span>
          <span class="text-regular">{{ organisme.id_organisme }}</span>
        </div>
        <div>
          <span class="text-medium">{{ $t('etablissement.code-etablissement') }}</span>
          <span class="text-regular">{{ organisme.uai }}</span>
        </div>
        <div>
          <span class="text-medium">{{ $t('etablissement.appellation-officielle') }}</span>
          <span class="text-regular">{{ organisme.nom }}</span>
        </div>
        <div>
          <span class="text-medium">{{ $t('etablissement.academie') }}</span>
          <span class="text-regular">{{ academie }}</span>
        </div>
        <div>
          <span class="text-medium">{{ $t('chorus.n-de-siret') }}</span>
          <span class="text-regular">
            {{ organisme.siret }}
          </span>
        </div>
        <div>
          <span class="text-medium">{{ $t('etablissement.secteur') }}</span>
          <span class="text-regular">
            {{ organisme.infos?.secteur === "PRIVE" ? $t("etablissement.prive") : $t("etablissement.public") }}
          </span>
        </div>
      </div>
    </div>

    <div id="adresses">
      <h2 class="s5 title-separator">
        <span class="bold">{{ $t('etablissement.adresses') }}</span>
      </h2>
      <div>
        <address v-if="hasPerm('can_view_as_maitre_compta')">
          <span class="text-medium">{{ $t('etablissement.adresse-de-facturation') }}</span>
          <p class="text-regular">
            <template v-if="organisme.adresse_facturation_1">
              {{ organisme.adresse_facturation_1 }}<br />
            </template>
            <template v-if="organisme.adresse_facturation_2">
              {{ organisme.adresse_facturation_2 }}<br />
            </template>
            <template v-if="organisme.cp_facturation || organisme. commune_facturation">
              {{ organisme.cp_facturation }} {{ organisme.commune_facturation }}<br />
            </template>
            {{ organisme.pays_facturation }}
          </p>
        </address>
        <address>
          <span class="text-medium">{{ $t('etablissement.adresse-de-livraison') }}</span>
          <p class="text-regular">
            <template v-if="organisme.adresse_livraison_1">
              {{ organisme.adresse_livraison_1 }}<br />
            </template>
            <template v-if="organisme.adresse_livraison_2">
              {{ organisme.adresse_livraison_2 }}<br />
            </template>
            <template v-if="organisme.cp_livraison || organisme. commune_livraison">
              {{ organisme.cp_livraison }} {{ organisme.commune_livraison }}<br />
            </template>
            {{ organisme.pays_livraison }}
          </p>
        </address>
      </div>
    </div>

    <p
      v-if="organisme.infos.type_facturation && hasPerm('can_view_as_maitre_compta')"
      id="reception_factures"
      class="text-medium"
    >
      {{ $t("etablissement.la-reception-des-factures-se-fait-par") }}
      <span>{{ organisme.infos.type_facturation }}</span>
    </p>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import IconWoman from "@/components/icons/IconWoman.vue";
import IconMan from "@/components/icons/IconMan.vue";
import { UilPhone, UilEnvelopeAlt } from "@iconscout/vue-unicons";

/**
 * Affiche les coordonnées de l'établissement ainsi que celles de son contact.
 */
export default {
  name: "EstablishmentContactInfos",
  components: {
    IconWoman,
    IconMan,
    UilPhone,
    UilEnvelopeAlt,
  },
  props: {
    /**
     * Établissement avec ses informations.
     */
    organisme: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["hasPerm", "isGE"]),
    academie() {
      const words = this.organisme.infos.academie.split("-");
      const capitalizedWords = words.map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`);
      return capitalizedWords.join(" ");
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/establishments/establishment_contact_infos.scss";
</style>
